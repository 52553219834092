import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

export const Title = styled.div`
    font-size: 5rem;
    font-weight: bold;
`

export const SubTitle = styled.a`
    font-size: 1rem;
`