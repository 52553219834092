import React, { useState } from 'react';
import { Container, Title, SubTitle } from './App.styles';
// import './App.scss';

function App() {
	const MAXVAL = 255;
	const MINVAL = 70;
	const [color, setColorValues] = useState<{ r: Number; g: Number; b: number }>({ r: 70, g: 0, b: 70 });
	const [shadow, setShadowValues] = useState<{ x: Number; y: Number }>({ x: 1, y: 1 });

	const handleMouseMove = (e: { clientX: number; clientY: number }) => {
		calculateColor(e.clientX, e.clientY);
		// calculateShadowDistance(e.clientX, e.clientY);
	};

	const calculateColor = (x: number, y: number) => {
		const mx = MAXVAL / window.innerWidth;
		const my = MAXVAL / window.innerHeight;
		const r = Math.max(Math.floor(x * mx), MINVAL);
		const g = Math.floor(((x + y) / 3) * mx);
		const b = Math.max(Math.floor((y * my) / 1.5), MINVAL / 2);
		setColorValues({ r, g, b });
	};

	const calculateShadowDistance = (x: number, y: number) => {
		const max = 10;
		const mx = x >= window.innerWidth / 2 ? max / window.innerWidth : -max / window.innerWidth;
		const my = y >= window.innerHeight / 2 ? max / window.innerHeight : -max / window.innerHeight;
		const xd = mx * x;
		const yd = my * y;
		setShadowValues({ x: xd, y: yd });
	};

	return (
		<Container style={{ backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})` }} onMouseMove={handleMouseMove}>
			<Title
				style={{
					color: `rgb(${color.b}, ${color.r}, ${color.g})`,
					textShadow: `rgb(${color.g}, ${color.b}, ${color.r}) 5px 5px 0px`,
				}}
			>
				Benni.io
			</Title>
			<SubTitle
				href='https://www.linkedin.com/in/bennibjorn'
				style={{ color: `rgb(${color.b}, ${color.r}, ${color.g})` }}
			>
				LinkedIn
			</SubTitle>
		</Container>
	);
}

export default App;
